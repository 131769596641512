import "./single.css"
import React, { useEffect, useRef, useState } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "@/css/@wordpress/block-library/build-style/style.css"
import "@/css/@wordpress/block-library/build-style/theme.css"

import Layout from "@/templates/layout"
import Seo from "@/components/seo"
import { Site, SiteDetails, SiteLogo, ProsCons, SiteScore } from "@/components/site-card"
import StickyMenu, { MenuItem, setupScrollSpy } from "@/components/sticky-menu"
import { useTranslation } from "gatsby-plugin-react-i18next"
import AuthorBlock from "@/components/author-block"

interface Props {
  data: {
    site: Site
  }
}

const PostTemplate = (props: any) => {
  const { t } = useTranslation()
  const { data: { site } } = props as Props
  const [items, setItems] = useState<MenuItem[]>([])
  const refContent = useRef<HTMLDivElement>(null)
  setupScrollSpy(refContent, 'h2', [
    site.siteFields.content.summary,
    site.siteFields.content.mainContent,
  ], setItems)
  const featuredImage = {
    data: site.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: site.featuredImage?.node?.alt || ``,
  }

  const seo = {
    title: site.seoTitle,
    description: site.seoDescription,
    schema: site.seoSchema,
    url: `/exchanges/${site.slug}`
  }
  let hasAlternateLinks = true
  if (site.siteCategories.nodes.map(node => node.slug).join(" ").indexOf("no-alternate-links") > -1) {
    hasAlternateLinks = false
  }
  if (!hasAlternateLinks) {
    (seo as any).prefixes = []
  }

  return (
    <Layout {...props} seo={seo} title={site.title}>
      <article className="blog-post site-single">
        <header className="site-header">
          <div className="section-content">
            <h1 itemProp="headline">{site.siteFields.title}</h1>
            {site.author && <AuthorBlock author={site.author.node} modified={site.modified} /> }
          </div>

          <div className="aside">
            {/* if we have a featured image for this post let's display it */}
            {featuredImage?.data && (<GatsbyImage image={featuredImage.data} alt={featuredImage.alt} />)}
            <a className="button" target="_blank" rel="noopener nofollow noreferrer" href={site.siteFields.url} itemProp="url">
              {t("visit-website", { site: site.title })}
            </a>
          </div>
        </header>

        <hr />

        <div className="container container-columns">
          <div className="column-sidebar">
            <div className="sticky">
              <StickyMenu items={items} />
              <SiteScores className="hidden-mobile" site={site} />
            </div>
          </div>
          <div className="column-primary container-menu-items" ref={refContent}>
            <SiteDetails site={site} />
            <section className="summary">{parse(site.siteFields.content.summary ?? "")}</section>

            <ProsCons pros={site.siteFields.pros} cons={site.siteFields.cons} />

            <section className="main-content" itemProp="articleBody"
              dangerouslySetInnerHTML={{__html: site.siteFields.content.mainContent }}
            />

            <hr />

            <SiteScores className="hidden-desktop" site={site} />
          </div>
        </div>
        <hr />
      </article>
    </Layout>
  )
}

function SiteTable() {
  return <table>
    <thead>
      <tr>
        <th></th>
        <th>Monthly Fee</th>
        <th>Overdraft Fee</th>
        <th>Interest (APY)</th>
        <th>Minimum balance to earn interest</th>
      </tr>
    </thead>
    <tbody>

      <tr>
        <td>Spend</td>
        <td>$0</td>
        <td>$36</td>
        <td>None</td>
        <td>N/a</td>
      </tr>

      <tr>
        <td>Reserve</td>
        <td>$0</td>
        <td>N/A</td>
        <td>None</td>
        <td>N/a</td>
      </tr>

      <tr>
        <td>Growth</td>
        <td>$0</td>
        <td>N/A</td>
        <td>0.40%</td>
        <td>$1</td>
      </tr>

    </tbody>

  </table>
}

function Score(props: { className: string, score: SiteScore }) {
  const { score, className } = props;
  return <div className={`site-score ${className ? className : ''}`}>
    <div className="site-score-inner">
      {score.name && <div className="score-name">{score.name}</div>}
      <div className="score-value">{score.score.toFixed(1)}</div>
      {score.text && <div className="score-text" dangerouslySetInnerHTML={{__html: score.text }} />}
    </div>
  </div>
}

function SiteScores(props: { className?: string, site: Site }) {
  const { t } = useTranslation()
  const { className, site } = props
  const ratings = site.siteFields.ratings || {}

  const classNames = ["site-scores"]
  if (className) { classNames.push(className) }

  return <div className={classNames.join(" ")}>
    <Score className="site-score-large" score={{name: t('Overall'), score: ratings.overall, text: t("overall-label", { title: site.title }) }} />
    <Score className="site-score-small" score={{name: t('Assets'), score: ratings.assets, text: ratings.assetsLabel}} />
    <Score className="site-score-small" score={{name: t('Ease of Use'), score: ratings.easeOfUse, text: ratings.easeOfUseLabel}} />
    <Score className="site-score-small" score={{name: t('Fees'), score: ratings.fees, text: ratings.feesLabel}} />
    <Score className="site-score-small" score={{name: t('Safety'), score: ratings.safety, text: ratings.safetyLabel}} />
    <Score className="site-score-small" score={{name: t('Staking'), score: ratings.staking, text: ratings.stakingLabel}} />
    <Score className="site-score-small" score={{name: t('Trading Features'), score: ratings.tradingFeatures, text: ratings.tradingFeaturesLabel}} />
  </div>
}

export default PostTemplate

export const pageQuery = graphql`
  query (
    $id: String!,
    $language: String!
  ) {
    site: wpSite(id: { eq: $id }) {
      ...SiteInformation
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
